var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-download"},[_c('AssetsTable',{attrs:{"data":_vm.reportData.data,"labelData":_vm.reportData.label,"page":_vm.reportData.page,"total":_vm.reportData.pageTotal},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"my_default",fn:function(ref){
var scope = ref.scope;
return [_c('span',{class:{
          succeed: scope.row.default == 1,
          error: scope.row.default == 2,
        }},[_vm._v(_vm._s(scope.row.my_default))])]}},{key:"report_url",fn:function(ref){
        var scope = ref.scope;
return [(scope.row.report_url)?_c('a',{staticClass:"mr-8",attrs:{"href":_vm.$request_base + scope.row.report_url.pdf,"target":"_blank"}},[_c('el-button',{attrs:{"type":"text"}},[_vm._v("查看")])],1):_vm._e(),(scope.row.report_url)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadUrlBtn(
            scope.row.report_url,
            scope.row.title || scope.row.number
          )}}},[_vm._v("下载 ")]):_vm._e(),_c('el-button',{staticStyle:{"color":"#F56C6C"},attrs:{"type":"text"},on:{"click":function($event){return _vm.delDownloadBtn( scope.$index,scope.row.id)}}},[_vm._v("删除 ")]),(scope.row.default == 2)?[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.reportAgainBtn(scope.row.id)}}},[_vm._v("重新生成")])]:_vm._e()]}}])}),_c('el-dialog',{attrs:{"title":"报告下载","visible":_vm.downloadDialog,"lock-scroll":false,"width":"400px","center":""},on:{"update:visible":function($event){_vm.downloadDialog=$event}}},[_c('div',{staticClass:"download-select justify-around"},[(_vm.downloadUrl.pdf)?_c('div',{class:[
          'download-pdf',
          'border-r4',
          { 'theme-border': _vm.downloadType == 'pdf' } ],on:{"click":function($event){_vm.downloadType = 'pdf'}}},[_c('el-image',{attrs:{"src":require('@/assets/images/report-pdf.png'),"fit":"contain"}}),_c('p',{staticClass:"mt-10"},[_vm._v("PDF格式")])],1):_vm._e(),(_vm.downloadUrl.doc)?_c('div',{class:[
          'download-pdf',
          'border-r4',
          { 'theme-border': _vm.downloadType == 'doc' } ],on:{"click":function($event){_vm.downloadType = 'doc'}}},[_c('el-image',{attrs:{"src":require('@/assets/images/report-doc.png'),"fit":"contain"}}),_c('p',{staticClass:"mt-10"},[_vm._v("word格式")])],1):_vm._e()]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"download-btn theme-back",attrs:{"type":"primary"},on:{"click":_vm.downloadBtn}},[_vm._v("下载")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }