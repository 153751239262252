/** 报告下载 */
<template>
  <div class="report-download">
    <AssetsTable :data="reportData.data" :labelData="reportData.label" :page="reportData.page"
      :total="reportData.pageTotal" @changePage="changePage">
      <template v-slot:my_default="{ scope }">
        <span :class="{
            succeed: scope.row.default == 1,
            error: scope.row.default == 2,
          }">{{ scope.row.my_default }}</span>
      </template>
      <template v-slot:report_url="{ scope }">
        <a v-if="scope.row.report_url" class="mr-8" :href="$request_base + scope.row.report_url.pdf" target="_blank">
          <el-button type="text">查看</el-button>
        </a>
        <el-button v-if="scope.row.report_url" type="text" @click="
            downloadUrlBtn(
              scope.row.report_url,
              scope.row.title || scope.row.number
            )
          ">下载
        </el-button>
        <el-button type="text" @click="delDownloadBtn( scope.$index,scope.row.id)" style="color:#F56C6C">删除 </el-button>
        <template v-if="scope.row.default == 2">
          <el-button type="text" @click="reportAgainBtn(scope.row.id)">重新生成</el-button>
        </template>
      </template>
    </AssetsTable>
    <!-- 下载框 -->
    <el-dialog title="报告下载" :visible.sync="downloadDialog" :lock-scroll="false" width="400px" center>
      <div class="download-select justify-around">
        <div v-if="downloadUrl.pdf" :class="[
            'download-pdf',
            'border-r4',
            { 'theme-border': downloadType == 'pdf' },
          ]" @click="downloadType = 'pdf'">
          <el-image :src="require('@/assets/images/report-pdf.png')" fit="contain"></el-image>
          <p class="mt-10">PDF格式</p>
        </div>
        <div v-if="downloadUrl.doc" :class="[
            'download-pdf',
            'border-r4',
            { 'theme-border': downloadType == 'doc' },
          ]" @click="downloadType = 'doc'">
          <el-image :src="require('@/assets/images/report-doc.png')" fit="contain"></el-image>
          <p class="mt-10">word格式</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="download-btn theme-back" @click="downloadBtn">下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AssetsTable from "@/components/Assets/table.vue";
import Page from "@/components/Page.vue";
import { getReportList, getReportDownloadAgain, delReport } from "@/api/personal";
export default {
  name: "ReportDownload",
  components: {
    AssetsTable,
    Page,
  },
  data () {
    return {
      downloadDialog: false, // 下载框
      downloadType: "", //下载类型
      downloadUrl: {}, //文件下载路径
      downloadfileName: "", //文件下载名称
      reportData: {
        label: [
          { label: "报告类型", value: "report_type" },
          { label: "报告主体", value: "title", width: "300px" },
          { label: "时间", value: "created_at" },
          { label: "状态", value: "my_default", width: "118px", isSlot: true },
          { label: "操作", value: "report_url", isSlot: true },
        ],
        data: null,
        page: 1,
        pageTotal: 0,
      }
    }
  },
  mounted () {
    this.getReportList();
  },
  methods: {
    // 下载列表
    async getReportList (page = 1) {
      try {
        let { data, status } = await getReportList(page);
        if (status == 200) {
          data.data.forEach((item) => {
            item["my_default"] = this.isReportStatus(item.default);
          });
          this.reportData.data = data.data;
          this.reportData.pageTotal = data.total
        }
      } catch (error) {
        return false;
      }
    },
    // 判断报告状态
    isReportStatus (val) {
      switch (val) {
        case 0:
          return "报告生成中";
          break;
        case 1:
          return "生成成功";
          break;
        case 2:
          return "生成失败";
          break;
        default:
          break;
      }
    },
    // 报告重新生成
    async reportAgainBtn (id) {
      try {
        let { status, msg } = await getReportDownloadAgain(id);
        if (status == 200 || status == 201) {
          this.$message.success(msg);
        } else {
          this.$message.error("生成失败，请稍后重试");
        }
      } catch (error) {
        return false;
      }
    },
    // 下载地址
    downloadUrlBtn (url, name) {
      this.downloadDialog = true;
      this.downloadUrl = url;
      this.downloadfileName = name.replace(".", "_");
    },
    // 下载
    downloadBtn () {
      if (this.downloadType) {
        let url = this.downloadUrl[this.downloadType];

        this.$http.defaults.baseURL = this.$request_base;
        this.$http.get(url, { responseType: "blob" }).then((data) => {
          let MIMEType = "application/msword";
          if (this.downloadType == "pdf") {
            MIMEType = "application/pdf";
          }

          let blob = new Blob([data.data], {
            type: MIMEType + ";charset=utf-8",
          });
          let objectUrl = URL.createObjectURL(blob);

          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(objectUrl, this.downloadfileName);
          } else {
            let link = document.createElement("a");
            link.href = objectUrl;
            link.setAttribute("download", this.downloadfileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素

            URL.revokeObjectURL(objectUrl);
          }
        });
      } else {
        this.$message.warning("请选择下载格式");
      }
    },
    // 删除
    delDownloadBtn (index, id) {
      this.$MessageBox.confirm('确定删除该报告吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        lockScroll: false
      }).then(async () => {
        try {
          let { status } = await delReport(id);
          if (status == 200) {
            this.reportData.data.splice(index, 1)
            if (!this.reportData.data.length) {
              this.getReportList();
            }
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        } catch (error) { }
      }).catch(() => { });

    },

    changePage (page) {
      this.reportData.page = page;
      this.getReportList(page);
    },
  },
};
</script>
<style lang="scss" scoped>
.report-download {
  height: 100%;
}
::v-deep .el-table {
  td.el-table__cell {
    .succeed {
      color: #18bd02;
    }
    .error {
      color: #f56c6c;
    }
  }
}
::v-deep .download-select {
  & > div {
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid;
    p {
      line-height: 1;
      text-align: center;
    }
  }
}
.download-btn {
  width: 150px;
}
</style>